<template>
  <header class="dark:bg-muted-800 absolute start-0 top-0 h-16 w-full bg-white">
    <div class="relative flex h-16 w-full items-center justify-between px-4">
      <NuxtLink
        to="/"
        class="text-muted-400 hover:text-primary-500 dark:text-muted-700 dark:hover:text-primary-500 transition-colors duration-300"
      >
        <ClientOnly>
          <WinxLogo width="90" />
        </ClientOnly>
      </NuxtLink>
      <div class="flex items-center justify-end gap-4">
        <BaseThemeToggle />
        <ToolbarLanguage />
        <AccountMenu horizontal />
      </div>
    </div>
    <ClientOnly>
      <div v-if="$route.name === 'research-form'" class="z-10 w-full">
        <BaseProgress
          :value="question.progress"
          size="xs"
          rounded="full"
          :key="`progress-header${question.progress}`"
        />
      </div>
    </ClientOnly>
  </header>
</template>

<script lang="ts" setup>
  import { useFormStore } from '@/stores/form';
  import { storeToRefs } from 'pinia';

  const formStore = useFormStore();

  const { question } = storeToRefs(formStore);
</script>
